/* eslint-disable no-use-before-define */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState, useRef } from 'react';
import { Button } from '../../../components/common.tsx';



export function FourSevenEightPage() {
    const [phase, setPhase] = useState<"inhale" | "hold" | "exhale">("inhale");
    const [countdown, setCountdown] = useState(4);
    const [total, setTotal] = useState(0)
    const [totalCount, setTotalCount] = useState(0)

    const [startFlag, setStartFlag] = useState(false)
    useEffect(() => {
        const phaseDurations = { inhale: 4, hold: 7, exhale: 8 };
        // Timer to handle countdown
        if (startFlag) {
            if (total === 0) {
                setTotal(phaseDurations[phase])
            }
        }
        const interval = startFlag && setInterval(() => {
            setCountdown((prev) => {
                if (prev === 1) {
                    if (phase === "inhale") {
                        setPhase("hold");
                        setCountdown(7); // Switch to hold
                        setTotal(7); // Switch to hold
                    } else if (phase === "hold") {
                        setPhase("exhale");
                        setCountdown(8); // Switch to exhale
                        setTotal(8); // Switch to hold

                    } else if (phase === "exhale") {
                        setPhase("inhale");
                        setCountdown(4); // Restart inhale
                        setTotal(4); // Switch to hold
                        setTotalCount(totalCount + 1)
                    }
                    return phaseDurations[phase];
                }
                return prev - 1;
            });
        }, 1000);

        // Calculate the fill percentage dynamically
        return () => {
            if (interval) {

                clearInterval(interval);
            }
        };
    }, [phase, countdown, startFlag]);

    const getPhaseText = () => {
        switch (phase) {
            case "inhale":
                return "Inhale deeply...";
            case "hold":
                return "Hold your breath...";
            case "exhale":
                return "Exhale slowly...";
            default:
                return "";
        }
    };

    const getPhaseIcon = () => {
        switch (phase) {
            case "inhale":
                return "/images/inhale.png";
            case "hold":
                return "/images/hold.png";
            case "exhale":
                return "/images/exhale.png";
            default:
                return "";
        }
    };



    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100 p-10">
            <label>Total : x{totalCount}</label>
            <h1 className="text-2xl font-semibold mb-4 text-gray-800">
                4-7-8 Breathing Technique
            </h1>
            <div className="relative w-48 ">
                {/* Outer circle */}
                <div className='m-auto w-fit'>

                    <CircularTimer speed={total} radius={100} imageSrc={getPhaseIcon()} />
                </div>
            </div>
            <p className="text-lg mt-6 text-gray-600">{getPhaseText()}</p>
            <p className="text-3xl font-bold mt-2 text-blue-500">{countdown}</p>
            <Button onClick={() => setStartFlag(true)}>Start</Button>
        </div>
    );
}

interface CircularTimerProps {
    /** Duration in seconds for the timer to complete a full circle */
    speed: number;
    radius: number;
    imageSrc?: string
}

const CircularTimer: React.FC<CircularTimerProps> = ({ speed, radius, imageSrc }) => {
    const circleRef = useRef<SVGCircleElement>(null);


    const strokeWidth = 40;
    const normalizedRadius = radius - strokeWidth / 2;
    const circumference = normalizedRadius * 2 * Math.PI;

    useEffect(() => {
        const circle = circleRef.current;
        if (circle) {
            // Reset styles before starting the new animation
            circle.style.transition = 'none';
            circle.style.strokeDasharray = `${circumference} ${circumference}`;
            circle.style.strokeDashoffset = `${circumference}`;
            if (speed !== 0) {

                // Trigger a reflow to ensure the style changes take effect
                circle.getBoundingClientRect();

                // Start the animation
                circle.style.transition = `stroke-dashoffset ${speed}s linear`;
                circle.style.strokeDashoffset = '0';
            }
        }
    }, [circumference, speed]);

    return (
        <div className='items-center justify-center flex'>
            <img className='absolute z-10 m-auto' style={{ width: radius }} src={imageSrc} alt='Hold Breath' />

            <svg
                height={radius * 2}
                width={radius * 2}
                className="transform -rotate-90"
            >
                {/* Background Circle */}
                <circle
                    stroke="#e6e6e6"
                    fill="transparent"
                    strokeWidth={strokeWidth}
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                />
                {/* Animated Circle */}
                <circle
                    ref={circleRef}
                    stroke="#3498db"
                    fill="transparent"
                    strokeWidth={strokeWidth}
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                    className="transition-all duration-1000 ease-linear"
                />
            </svg>
        </div>
    );
};

export default CircularTimer;