import { collection, getDocs, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { captureException, withScope } from '@sentry/react';
import { collections } from './types.query.ts';
import { db } from '../firebase/config.firbase.ts';
import { useAuth } from '../contexts/AuthContext.tsx';

interface OfferData {
    paymentLink: string;
    price: string;
    title: string;
    subTitle: string;
    id: string;
}

/**
 *
 * @param productId - Product Id of offer wanting to be requested
 * @returns - Returns Offer Meta Data
 */
export async function getOfferData(
    productId: string
): Promise<OfferData | undefined> {
    const q = query(
        collection(db, collections.offers),
        where('id', '==', productId)
    );
    const snapshots = await getDocs(q);
    if (snapshots.docs.length > 0) {
        return {
            ...snapshots.docs[0].data(),
        } as OfferData;
    }
    return undefined;
}

export function useGetOffer(productId: string): {
    loading: boolean;
    error?: string;
    data?: OfferData;
} {
    const { user } = useAuth();
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<OfferData>({
        id: '',
        paymentLink: '',
        price: '',
        subTitle: '',
        title: '',
    });
    useEffect(() => {
        getOfferData(productId)
            .then((response) => {
                if (response) {
                    setData(response);
                }
                setError('Invalid Product');
                setLoading(false);
            })
            .catch((err) => {
                setError('Error in fetching Product Data Please try again');
                setLoading(false);
                withScope((scope) => {
                    scope.setExtra('uid', user?.uid);
                    scope.setExtra('action', 'load_purchase');
                    captureException(err);
                });
            });
    }, [productId]);

    return { data, loading, error };
}
