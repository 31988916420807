import React from 'react';
import LoadingOverlay from '../../components/LoadingOverlay.tsx';
import { Button } from '../../components/common.tsx';
import { useGetOffer } from '../../queries/offers.query.ts';

const getProductId = () => {
    return localStorage.getItem('product_id') || '';
};

function SubscriptionPage() {
    const { loading, data: offer } = useGetOffer(getProductId());
    if (loading) {
        return <LoadingOverlay isLoading />;
    }
    return (
        <div className="flex h-full   ">
            <div className="md:w-5/12 w-full bg-gray-100  p-3 h-full ">
                <div className=" w-full bg-white rounded-lg shadow-lg p-4  h-full flex items-center justify-center pt-0">
                    <div>
                        <img
                            src="/images/blob_1.png"
                            alt="abstract blob"
                            className="w-6/12 mx-auto max-w-32"
                        />
                        <h1 className="text-3xl font-bold mb-2 text-center">
                            {offer?.title}
                        </h1>
                        <p className="text-gray-500 text-center pb-4">
                            {offer?.subTitle}
                        </p>

                        <div className="bg-blue-50 p-4 rounded-md mb-6">
                            <h2 className="text-xl font-semibold text-blue-900">
                                Why Go Premium?
                            </h2>
                            <ul className="list-disc pl-5 mt-2 text-blue-800">
                                <li>Access exclusive to all content.</li>
                                <li>Personalized mindfulness programs.</li>
                                <li>Early access to new content & features.</li>
                                <li>Ad-free experience.</li>
                            </ul>
                        </div>

                        <Button
                            onClick={() => {
                                window.location.href =
                                    `${offer?.paymentLink}` || '';
                            }}
                        >
                            Purchase
                        </Button>
                        <p className="text-sm text-gray-500 mt-3 text-center">
                            By starting your free trial, you agree to our{' '}
                            <a
                                href="/terms"
                                className="text-blue-500 hover:underline"
                            >
                                Terms of Service
                            </a>{' '}
                            and{' '}
                            <a
                                href="/privacy"
                                className="text-blue-500 hover:underline"
                            >
                                Privacy Policy
                            </a>
                            .
                        </p>
                    </div>
                </div>
            </div>
            <div
                className="w-7/12 max-md:hidden p-20 h-full flex flex-col justify-end"
                style={{ backgroundColor: '#8369FF' }}
            >
                <img
                    src="/images/abstract_bg.png"
                    alt="Background"
                    className="max-w-md w-8/12"
                />
                <div>
                    <h1 className="text-6xl text-white pb-4">
                        You&apos;re so close!
                    </h1>
                    <h2 className="pb-4 text-white">
                        Setup payment now to get full access to all our wellness
                        content
                    </h2>
                </div>
            </div>
        </div>
    );
}

export default SubscriptionPage;
