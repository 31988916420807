import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { captureException, withScope } from '@sentry/react';
import { Button } from '../../components/common.tsx';

import {
    setUserDataHasSubscribed,
    setUserDataInterests,
} from '../../queries/users.query.ts';
import { useAuth } from '../../contexts/AuthContext.tsx';
import { routeNames } from '../../navigation/types.navigation.ts';
import { useGetAllInterests } from '../../content/interests.query.content.ts';

type OnboardTemplate = {
    header: string;
    description: string;
    children: ReactNode;
};

function Pill(props: {
    title: string;
    isSelected: boolean;
    onClick: () => void;
}) {
    const { isSelected, onClick, title } = props;
    return (
        <button
            type="button"
            onClick={onClick}
            className={` rounded-full w-full  ${isSelected ? 'text-white bg-blue-400' : 'text-blue-400 bg-white'} border-blue-400 border font-bold text-lg text-left px-3 py-1 md:px-5 md:py-2`}
        >
            {title}
        </button>
    );
}

export function InterestList(props: {
    selectedInterests: number[];
    setSelectedInterests: React.Dispatch<React.SetStateAction<number[]>>;
}) {
    const { selectedInterests, setSelectedInterests } = props;

    const { data: interests } = useGetAllInterests();
    async function onToggleInterestHandler(id: number) {
        if (selectedInterests.includes(id)) {
            const newSelectedInterests = selectedInterests.filter(
                (item) => item !== id
            );
            setSelectedInterests(newSelectedInterests);
        } else {
            const newSelectedInterests = [...selectedInterests, id];
            setSelectedInterests(newSelectedInterests);
        }
    }

    return (
        <div className="flex flex-wrap w-full">
            {interests.map((item) => (
                <div className="pb-2 pr-4">
                    <Pill
                        isSelected={selectedInterests.includes(item.id)}
                        title={item.title}
                        onClick={() => onToggleInterestHandler(item.id)}
                    />
                </div>
            ))}
        </div>
    );
}

const onboardingSlidesData: OnboardTemplate[] = [
    {
        header: 'Start your journey',
        description:
            'Welcome to Nook, your peaceful space for motherhood. Designed to support and uplift you on this beautiful yet challenging journey.',
        children: (
            <div className="flex flex-1 md:flex-none justify-center items-center max-w-80 ">
                <img src="/images/blob_3.png" alt="" />
            </div>
        ),
    },
    {
        header: 'What do you need support with?',
        description:
            'Select areas where you need emotional support. Nook will recommend content to meet your unique needs. ',
        children: <div />,
    },
    {
        header: 'Your Trial Starts Now',
        description:
            'Enjoy your 3 day trial period and explore all the content we provide on Nook',
        children: (
            <div className="flex flex-1 md:flex-none justify-center items-center max-w-80 ">
                <img src="/images/blob_4.png" alt="" />
            </div>
        ),
    },
];
function OnboardPage() {
    const [selectedInterests, setSelectedInterests] = useState<number[]>([]);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const { user, completeOnboardHandler, fetchUserData, userData } = useAuth();
    const navigate = useNavigate();
    const requestSent = useRef(false);
    const onboardingSlides = onboardingSlidesData;
    useEffect(() => {
        if (!userData?.hasSubscribed && user?.uid) {
            setUserDataHasSubscribed(user?.uid)
                .then(() => {
                    fetchUserData();
                })
                .catch((err) => {
                    withScope((scope) => {
                        scope.setExtra('uid', user.uid);
                        scope.setExtra(
                            'action',
                            'update_user_subcription_flag'
                        );
                        captureException(err);
                    });
                });
        }
    }, []);

    onboardingSlides[1].children = (
        <InterestList
            selectedInterests={selectedInterests}
            setSelectedInterests={setSelectedInterests}
        />
    );

    const onNextHandler = async () => {
        if (
            currentSlideIndex === onboardingSlides.length - 1 &&
            requestSent.current === false
        ) {
            if (user?.uid) {
                try {
                    requestSent.current = true;
                    await completeOnboardHandler();
                    await fetchUserData();
                    navigate(routeNames.nook);
                    requestSent.current = false;
                } catch (e) {
                    withScope((scope) => {
                        scope.setExtra('uid', user?.uid);
                        scope.setExtra('action', 'update_user_obnboard_flag');
                        captureException(e);
                    });
                }
            }
        } else if (
            user &&
            currentSlideIndex === 1 &&
            requestSent.current === false
        ) {
            requestSent.current = true;
            try {
                await setUserDataInterests(user.uid, selectedInterests);
            } catch (e) {
                withScope((scope) => {
                    scope.setExtra('uid', user?.uid);
                    scope.setExtra('action', 'onboarding_save_interests');
                    captureException(e);
                });
            }
            setCurrentSlideIndex(currentSlideIndex + 1);
            requestSent.current = false;
        } else {
            setCurrentSlideIndex(currentSlideIndex + 1);
        }
    };
    const ActionButtonText =
        currentSlideIndex === onboardingSlides.length - 1 ? 'Complete' : 'Next';

    function isDisabled(pageIndex: number) {
        if (pageIndex === 1 && selectedInterests.length === 0) return true;
        return false;
    }
    return (
        <div className="p-4 flex-1 flex flex-col h-full ">
            <div>
                {currentSlideIndex !== 0 && (
                    <button
                        type="button"
                        className="self-start max-md:hidden "
                        onClick={() => {
                            setCurrentSlideIndex(currentSlideIndex - 1);
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            className="text-2xl"
                        />
                    </button>
                )}
            </div>
            <div className="items-center flex flex-col justify-center w-full  flex-1">
                {onboardingSlides[currentSlideIndex].children}
            </div>
            <div className=" w-full  text-center ">
                <h1 className="pb-2">
                    {onboardingSlides[currentSlideIndex].header}
                </h1>
                <p>{onboardingSlides[currentSlideIndex].description}</p>
            </div>
            <div className=" text-center w-full  items-center">
                <div className="flex justify-center my-6 ">
                    {onboardingSlides.map((_, index) => (
                        <span
                            key={_.header}
                            // eslint-disable-next-line prettier/prettier
                            className={`w-3 h-3 mx-1 rounded-full 
                                ${index <= currentSlideIndex ? 'bg-blue-500' : 'bg-gray-300'}`}
                        />
                    ))}
                </div>

                <Button
                    data-testid="onboarding-next-btn"
                    disabled={isDisabled(currentSlideIndex)}
                    onClick={onNextHandler}
                >
                    {ActionButtonText}
                </Button>
            </div>
        </div>
    );
}

export default OnboardPage;
